<template>
  <div>
    <b-card class="decomposition-card custom-card">
      <b-row class="flex-nowrap d-flex align-items-center">
        <b-col cols="auto">
          <b-form-group class="mb-0" style="min-width: 300px">
            <DatasourceSelect v-model="datasourceSelected" mode="single" :disabled="showOverlay" />
          </b-form-group>
        </b-col>
        <b-col cols="auto d-flex">
          <b-form-group class="mb-0" :title="$t('option_chart')">
            <label class="toolbar-title text-primary">{{ $t('option_chart') }}</label>
            <div class="d-flex model-actions d-flex align-center align-items-center justify-content-center">
              <b-media-aside>
                <b-button class="model-actions_item position-relative mr-0" rounded variant="light-primary" size="sm">
                  <feather-icon icon="SettingsIcon" size="18" />
                  <div class="ml-advanced-config-container decomposition-settings-container" :class="{ 'dark-layout': isDark }">
                    <div class="ml-advanced-config-main" :style="{ minHeight: `170px`, maxWidth: '350px' }">
                      <b-row class="mb-1">
                        <b-col cols="6">
                          <div class="label-form-group">
                            <family-icon style="width: 16px; height: 16px; margin-right: 10px; color: #397bff" /> <span>{{ $t('font_family') }}</span>
                          </div>
                          <b-form-group :label="``">
                            <vue-select :options="chartFontFamilyChoices" v-model="chartFontFamily" />
                          </b-form-group>
                        </b-col>
                        <b-col cols="6">
                          <div class="label-form-group">
                            <size-icon style="width: 16px; height: 16px; margin-right: 10px; color: #397bff" /><span>{{ $t('text_size') }}</span>
                          </div>
                          <b-form-group :label="``">
                            <b-form-input v-model="chartTextSize" :placeholder="$t('text_size')" type="number" />
                          </b-form-group>
                        </b-col>
                        <b-col cols="12">
                          <div class="label-form-group">
                            <color-icon style="width: 16px; height: 16px; margin-right: 10px; color: #397bff" /><span>{{ $t('text_color') }}</span>
                          </div>
                          <b-form-group :label="``">
                            <ColorPicker v-model="chartTextColor" :placeholder="$t('text_color')" />
                          </b-form-group>
                        </b-col>
                      </b-row>
                    </div>
                  </div>
                </b-button>
              </b-media-aside>
            </div>
          </b-form-group>
          <b-form-group class="mb-0 ml-1">
            <CustomInput :value="frequency" @change="(value) => (frequency = value)" label="frequency" class="cl_frequency" type="number" />
          </b-form-group>
        </b-col>
        <b-col cols="auto" class="ml-auto d-flex align-items-center">
          <SummaryData :chartData="chartData.data" />
          <Downloader ref="refDownload" :downloadImage="downloadImage" :downloadHtml="downloadHtml" :downloadCsv="downloadCsv" />
        </b-col>
      </b-row>
    </b-card>
    <b-overlay :show="showOverlay" variant="transparent" opacity="0.85" blur="2px" rounded="sm">
      <template #overlay>
        <div class="text-center">
          <div class="mb-1 text-center">
            <b-spinner />
          </div>
          <b-button variant="outline-danger" @click="terminateProgress" size="sm" aria-describedby="cancel-label">{{ $t('terminate') }} </b-button>
        </div>
      </template>
      <DecompositionChart ref="chart" :chartFontDecomposition="chartFontDecomposition" :chart="chart" :nameComponent="nameComponent" />
    </b-overlay>
    <Timeplayer ref="timeplayer" :liveMode="liveMode" v-model="date" :moveToEnd="moveToEnd" @changeStatusTimePlayer="funcChangeStatusTimePlayer" />
  </div>
</template>

<script>
const _ = require('lodash')
const zlib = require('zlib')
import { ThemeConfig } from '@/mixins/ThemeMixin.js'
import MathMixin from '@/mixins/MathMixin.js'
import SelectDatasourceMixin from '@/mixins/SelectDatasourceMixin'
import PausePlayerMixin from '@/mixins/PausePlayerMixin'
import ToastificationContent from '@/@core/components/toastification/ToastificationContent.vue'
import Timeplayer from '../../common/Timeplayer.vue'
import DatasourceSelect from '../../common/DatasourceSelect/DatasourceSelect.vue'
import Downloader from '../../common/Downloader.vue'
import SummaryData from '../../common/SummaryData.vue'
import RadioGroup from '../../common/RadioGroup.vue'
import CustomInput from '../../common/CustomInput.vue'
import DecompositionChart from './DecompositionChartComponent.vue'
import ColorPicker from '../../common/ColorPicker.vue'
import * as math from 'mathjs'
import ChartYearMixin from '@/mixins/ChartYearMixin'
import { OPTION_PLOTLY_PALETTE } from '@/constants/colors'
import { DECOMPOSITION_API, API_LAMBDA_DECOMPOSITON_CORRELATION } from '@/constants/urls'

// icon
import FamilyIcon from '/static/images/layer-config/prepend/font-family.svg'
import ColorIcon from '/static/images/layer-config/prepend/font-color.svg'
import SizeIcon from '/static/images/layer-config/prepend/font-size.svg'

export default {
  name: 'decomposition',
  components: { CustomInput, DecompositionChart, Timeplayer, DatasourceSelect, Downloader, SummaryData, RadioGroup, ColorPicker, FamilyIcon, ColorIcon, SizeIcon },
  mixins: [SelectDatasourceMixin, PausePlayerMixin, ToastificationContent, ChartYearMixin, ThemeConfig, MathMixin],
  data() {
    return {
      chart: {},
      showOverlay: false,
      moveToEnd: 0,
      sttTimeplayer: 'pausing',
      chartFontFamilyChoices: ['Noto Sans JP', 'Calibri', 'Sans Serif', 'Serif', 'Cursive', 'Monospace'],
      nameComponent: 'decomposition',
      controller: null,
      setTimeoutRun: null,
      isRunRequest: false,
      idRequest: null,
      clickCancel: false,
    }
  },
  computed: {
    date: {
      get() {
        return this.$store.state.tabs[this.$options.name].date
      },
      set(date) {
        try {
          const dataFull = this.$refs.timeplayer.dates
          const mode = this.$refs.timeplayer.mode
          if (this.sttTimeplayer == 'playing') {
            if (mode == 'single') {
              let indexDate0 = dataFull.indexOf(date[0])
              let index0Temp = Number(this.frequency) - 1
              if (indexDate0 < this.frequency - 1 && index0Temp <= dataFull.length) {
                date[0] = dataFull[index0Temp]
              }
            }
            if (mode == 'addition') {
              let indexDate0 = dataFull.indexOf(date[0])
              let indexDate1 = dataFull.indexOf(date[1])
              let index1Temp = Number(indexDate0) + Number(this.frequency) - 1
              if (math.abs(indexDate1 - indexDate0) < this.frequency && index1Temp < dataFull.length) {
                date[1] = dataFull[index1Temp]
              }
            }
          }
        } catch {}
        this.$store.commit(`tabs/SET_${this.$options.name.toUpperCase()}`, { date })
      },
    },
    mode: {
      get() {
        return this.$store.state.tabs[this.$options.name].mode
      },
      set(mode) {
        this.$store.commit(`tabs/SET_${this.$options.name.toUpperCase()}`, { mode })
      },
    },
    frequency: {
      get() {
        return this.$store.state.tabs[this.$options.name].frequency
      },
      set(frequency) {
        this.$store.commit(`tabs/SET_${this.$options.name.toUpperCase()}`, { frequency })
      },
    },
    modeChoices() {
      return [
        { text: this.$i18n.t('STL'), value: 'loess', tooltip: 'stl_tittle' },
        // { text: this.$i18n.t('TSA Additive'), value: 'additive', tooltip: 'tsa_additive_title' },
        // { text: this.$i18n.t('TSA Multiplicative'), value: 'multiplicative', tooltip: 'tsa_multiplicative_title' },
      ]
    },
    chartFlag() {
      return `${this.chartData.data}__${this.frequency}__${this.mode}`
    },
    minChartDataLength() {
      if (this.mode === 'loess') {
        return Number(this.frequency)
      } else {
        return Number(this.frequency) * 2
      }
    },
    tempProject() {
      return this.$store.state.ecoplot.tempProject
    },
    markerColor() {
      return OPTION_PLOTLY_PALETTE.D3
    },
    liveMode() {
      try {
        let check = false
        if (!this.datasources || !this.datasourceSelected) return false
        let arryIdDatasources = []
        const datasources = this.$store.state.datasource.datasources
        datasources.map((data) => {
          arryIdDatasources.push(data.id)
        })
        arryIdDatasources = _.unionBy(arryIdDatasources)
        this.datasourceSelected.map((data) => {
          let indexData = arryIdDatasources.indexOf(data.datasource)
          if (indexData > -1 && datasources[indexData].live) {
            check = true
            return check
          }
        })
        return check
      } catch {}
      return false
    },
    countLiveMode() {
      return this.$store.state.settings.countLiveMode
    },
    initProject() {
      return this.$store.state.ecoplot.initProject
    },
    chartFontFamily: {
      get() {
        try {
          return this.$store.state.tabs[this.nameComponent].chartFontFamily
        } catch {
          return this.$store.state.settings.chartFontFamily
        }
      },
      set(chartFontFamily) {
        this.$store.commit(`tabs/SET_${this.nameComponent.toUpperCase()}`, { chartFontFamily })
      },
    },
    chartTextSize: {
      get() {
        try {
          return this.$store.state.tabs[this.nameComponent].chartTextSize
        } catch {
          return this.$store.state.settings.chartTextSize
        }
      },
      set(chartTextSize) {
        this.$store.commit(`tabs/SET_${this.nameComponent.toUpperCase()}`, { chartTextSize })
      },
    },
    chartTextColor: {
      get() {
        try {
          return this.$store.state.tabs[this.nameComponent].chartTextColor
        } catch {
          return this.$store.state.settings.chartTextColor
        }
      },
      set(chartTextColor) {
        this.$store.commit(`tabs/SET_${this.nameComponent.toUpperCase()}`, { chartTextColor })
      },
    },
    chartFontDecomposition() {
      let font = {}
      try {
        if (this.chartFontFamily) {
          font.family = this.chartFontFamily
        }
        if (this.chartTextSize) {
          font.size = this.chartTextSize
        }
        if (this.chartTextColor) {
          font.color = this.chartTextColor
        }
      } catch {}
      return font
    },
  },
  asyncComputed: {
    chartData: {
      default: { data: [], dates: [] },
      async get() {
        try {
          this.frequency
          let chartData = { data: [], dates: [] }
          // min date and max date (range case and single case)
          const minDate = this.date.length === 2 ? this.date[0] : this.date.length === 1 ? '0000-00-00 00:00:00' : ''
          const maxDate = this.date.length === 2 ? this.date[1] : this.date.length === 1 ? this.date[0] : ''

          const timeseriesDatasource = this.datasourceSelected.filter((ds) => ds.location)[0]
          if (timeseriesDatasource) {
            const { datasource: datasourceId, location, item } = timeseriesDatasource
            const locationsItems = [location + '-' + item]
            const records = await this.selectRangeByLocationsItems(datasourceId, locationsItems, minDate, maxDate)
            if (records && records[locationsItems]) {
              if (this.minChartDataLength > 1 && records[locationsItems].length >= this.minChartDataLength) {
                chartData.dates = records.dates
                chartData.data = records[locationsItems]
              } else {
                chartData.dates = [records.dates[0]]
                chartData.data = [0]
                this.chart.data = { dates: [records.dates[0]], original: [null], remainder: [null], seasonal: [null], trend: [null] }
              }
            }
          }
          return chartData
        } catch (err) {
          this.showOverlay = false
        }
      },
    },
  },
  watch: {
    datasourceSelected() {
      this.moveToEnd++
    },
    chartFlag() {
      if (this.selectedDatasources.length) {
        this.runExecutableDecomposition()
      } else {
        this.chart = {}
      }
    },
    countLiveMode() {
      if (this.selectedDatasources.length) {
        this.runExecutableDecomposition()
      } else {
        this.chart = {}
      }
    },
  },
  methods: {
    gunZipArrayBuffer(compressedBuffer) {
      return new Promise((resolve, reject) => {
        zlib.gunzip(compressedBuffer, (err, dezipped) => {
          if (err) {
            resolve()
            return
          }
          try {
            // Chuyển đổi Buffer thành chuỗi
            const decompressedText = dezipped.toString('utf-8')
            // Chuyển chuỗi JSON thành object
            const data = JSON.parse(decompressedText)
            resolve(data)
          } catch {}
          resolve()
        })
      })
    },
    async delay(ms) {
      return new Promise((resolve) => setTimeout(() => resolve(), ms))
    },
    terminateProgress() {
      // Hủy request đang chạy nếu có
      this.clickCancel = true
      try {
        if (this.controller) {
          this.controller.abort()
          this.showOverlay = false
          this.isRunRequest = false
        }
      } catch {}
    },
    funcChangeStatusTimePlayer(status) {
      if (status == 'playing' || status == 'pausing') {
        this.sttTimeplayer = status
      }
    },
    showChart(decomData) {
      this.showOverlay = false
      if (decomData.status === 'error') {
        this.$toast({ component: ToastificationContent, props: { title: this.$t('calculated_failed'), text: decomData.message, icon: 'SlashIcon', variant: 'danger', timeout: 3000 } })
      } else if (decomData.status === 'success') {
        this.chart = { data: { ...decomData.data, dates: this.chartData.dates, original: this.chartData.data } }
      }
    },
    async runExecutableDecomposition() {
      if (this.mode === 'loess' && this.chartData.data.length && (this.chartData.data.length < this.frequency || this.frequency < 2)) {
        // let max = this.chartData.data.length < 2 ? 2 : this.chartData.data.length;
        // this.$toast({ component: ToastificationContent, props: { title: this.$t('calculated_failed'), text: this.$i18n.t('decomposition_warning', { max: max }), icon: 'SlashIcon', variant: 'danger',timeout:3000  } });
        this.showOverlay = false
        this.isRunRequest = false
        return
      }

      if (this.mode !== 'loess' && this.chartData.data.length && (this.chartData.data.length < this.frequency * 2 || this.frequency < 2)) {
        // let max = parseInt(this.chartData.data.length / 2) < 2 ? 2 : parseInt(this.chartData.data.length / 2);
        // this.$toast({ component: ToastificationContent, props: { title: this.$t('calculated_failed'), text: this.$i18n.t('decomposition_warning', { max: max }), icon: 'SlashIcon', variant: 'danger' } });
        this.showOverlay = false
        this.isRunRequest = false
        return
      }

      if (this.selectedDatasources.length && this.chartData.data.length) {
        this.idRequest = performance.now()
        let idRequestNow = _.cloneDeep(this.idRequest)
        try {
          if (this.setTimeoutRun) clearTimeout(this.setTimeoutRun)
        } catch {}
        setTimeout(() => {
          if (this.isRunRequest && idRequestNow == this.idRequest) {
            this.showOverlay = true
          }
        }, 1500)
        this.isRunRequest = true
        // this.showOverlay = true
        // Tạo một AbortController mới
        this.controller = new AbortController()
        const signal = this.controller.signal
        const body = {
          data: this.chartData.data,
          freq: this.frequency,
          mode: this.mode,
        }
        try {
          let checkFullNA = false
          if (this.chartData && this.chartData.data && this.chartData.data.length) {
            checkFullNA = true
            for (let i = 0; i < this.chartData.data.length; i++) {
              if (!this.isNaValue(this.chartData.data[i])) {
                checkFullNA = false
                break
              }
            }
          }
          let decomData = {
            code: 200,
            data: {
              remainder: [],
              seasonal: [],
              trend: [],
            },
            status: 'success',
          }
          if (!checkFullNA) {
            // const response = await fetch(DECOMPOSITION_API, {
            //   method: 'POST',
            //   headers: {
            //     Accept: 'application/json',
            //     'Content-Type': 'application/json',
            //     Authorization: `Token ${localStorage.getItem('django-authentication-token')}`,
            //   },
            //   body: JSON.stringify(body),
            // })
            let body2 = {
              data: body,
              from_tab: 'decomposition',
            }
            const compressedData = zlib.gzipSync(JSON.stringify(body2))
            const response = await fetch(API_LAMBDA_DECOMPOSITON_CORRELATION, {
              signal,
              method: 'POST',
              body: compressedData.toString('base64'),
            })
            const base64CompressedData = await response.json()
            // Giải mã base64 thành Buffer
            const compressedBuffer = Buffer.from(base64CompressedData, 'base64')
            // Giải nén dữ liệu Gzip
            decomData = await this.gunZipArrayBuffer(compressedBuffer)
          } else {
            decomData = {
              code: 200,
              data: {
                remainder: this.chartData.data,
                seasonal: this.chartData.data,
                trend: this.chartData.data,
              },
              status: 'success',
            }
          }
          this.isRunRequest = false
          this.showChart(decomData)
        } catch (error) {
          console.log('error:', error)
          this.isRunRequest = false
          this.showOverlay = false
          if (!this.clickCancel) {
            if (body && body.data && body.data.length > 1500000) {
              this.$toast({ component: ToastificationContent, props: { title: this.$t('error'), text: this.$t('The_calculation_data_is_too_large'), icon: 'SlashIcon', variant: 'danger', timeout: 3000 } })
            } else {
              this.$toast({ component: ToastificationContent, props: { title: this.$t('error'), text: this.$t('calculated_failed'), icon: 'SlashIcon', variant: 'danger', timeout: 3000 } })
            }
          }
        }
        this.idRequest = performance.now()
      }
      this.clickCancel = false
    },
    downloadHtml() {
      this.$refs.chart.asHtml(this.tempProject.name + '_Decomposition')
    },
    downloadImage() {
      this.$refs.chart.asImage(this.tempProject.name + '_Decomposition')
    },
    downloadCsv() {
      let data = (this.chart || {}).data || {}
      if (!this.date.length) return
      if (Object.keys(data).length === 0) return

      let csvContent = 'data:text/csv;charset=utf-8,'
      csvContent += 'No,Date,Original,Seasonal,Trend,Remainder\r\n'
      data.dates.forEach((date, index) => {
        csvContent += '' + (index + 1) + ',' + date + ',' + data.original[index] + ',' + data.seasonal[index] + ',' + data.trend[index] + ',' + data.remainder[index] + '\r\n'
      })
      this.$refs.refDownload.download({
        filename: this.tempProject.name + '_Decomposition.csv',
        data: csvContent,
      })
    },
  },
}
</script>

<style lang="scss">
.decomposition-card .cl_frequency {
  width: 65px;
}

.ml-advanced-config-main {
  background-color: white;
  border-radius: 7px;
  padding: 10px;
}
.dark-layout .ml-advanced-config-main {
  background-color: #283046;
}

.decomposition-settings-container {
  cursor: initial;
  position: absolute;
  left: -155px;
  top: 15px;
  transform: scale(0);
  opacity: 0;
  transform-origin: 150px 0;
  z-index: 1;
  border-radius: 7px;
  box-shadow: rgb(0 0 0 / 25%) 0px 25px 50px -12px;
  padding: 10px;
  width: 100%;
  margin-top: 40px;
  transition: transform 0.4s, opacity 0.4s;
  backdrop-filter: blur(2px);
  background-color: #fff;
  width: 350px;
  z-index: 11;
  backdrop-filter: blur(2px);
  background-color: rgba(57, 123, 255, 0.12) !important;
}
.ml-advanced-config-container.dark-layout {
  background-color: #283046;
}
.ml-advanced-config-container .ml-slider {
  margin-bottom: 30px;
}

.decomposition-card .model-actions .b-avatar:not(.model-icon) {
  width: 100% !important;
  padding: 7px;
}
.decomposition-card .model-actions .model-actions_item {
  // color: #397bff;
  padding: 7px;
  text-align: start;
  border: 1px solid rgba(115, 103, 240, 0.24);
  border-radius: 0.357rem;
  padding: 4px;
  display: flex;
  position: relative;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  width: 50px;
  height: 42px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.decomposition-card .model-actions .model-actions_item .spinner-model {
  position: absolute;
  top: -5px;
  right: -5px;
  width: 12px;
  height: 12px;
}
.decomposition-card .model-actions .model-actions_item .toolbar-title {
  margin-bottom: 4px;
}
.decomposition-card .model-actions .model-actions_item:focus .ml-advanced-config-container,
.decomposition-card .model-actions .model-actions_item:focus-within .ml-advanced-config-container,
.decomposition-card .model-actions .model-actions_item .ml-advanced-config-container:hover {
  transform: scale(1);
  opacity: 1;
}
.decomposition-card .model-actions .b-avatar .action-title {
  text-transform: uppercase;
  margin-left: 7px;
}
.label-form-group {
  padding-bottom: calc(0.438rem + 1px);
  margin-bottom: 0;
  font-weight: 500;
  font-size: 14px;
  display: flex;
  align-items: center;
  text-transform: uppercase;
  color: #808080;
}
</style>
